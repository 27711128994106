<template>
    <v-container class="terms">
        <v-row justify="center">
            <v-col>
                <h2 class="text-center ma-6">
                    DISCLAIMER AND TERMS OF USE AGREEMENT
                </h2>
                <h3 class="text-center ma-6">Introduction</h3>
                <p>This site is owned and operated by {{ businessName }}.</p>
                <p>
                    The author and publisher of this site and the accompanying
                    materials make no representation or warranties with respect
                    to the accuracy, applicability, fitness or completeness of
                    the contents of this site.
                </p>
                <p>
                    The author and publisher disclaim any warranties (express or
                    implied), merchantability, or fitness for any particular
                    purpose. The author and publisher shall in no event be held
                    liable to any party for any direct, indirect, punitive,
                    special, incidental or other consequential damages arising
                    directly or indirectly from any use of this material, which
                    is provided "as is", and without warranties.
                </p>
                <p>
                    Every effort has been made to accurately represent this
                    product and its potential. Any examples in these materials
                    are not be interpreted as a promise or guarantee of
                    earnings.
                </p>
                <p>
                    The author and publisher do not warrant the performance,
                    effectiveness or applicability of any sites listed or linked
                    to in this site. All links are for information purposes only
                    and are not warranted for content, accuracy or any other
                    implied or explicit purpose.
                </p>
                <p>
                    As always, the advice of a competent legal, tax, accounting
                    or other professional should be sought.
                </p>
                <p>
                    This site is © copyrighted and is protected under the US
                    Copyright Act of 1976 and all other applicable
                    international, federal, state and local laws, with ALL
                    rights reserved. No part of this may be copied, or changed
                    in any format, sold, or used in any way other than what is
                    outlined within this site under any circumstances without
                    express permission.
                </p>

                <h2 id="privacy" class="text-center ma-6">PRIVACY POLICY</h2>
                <p>
                    For details on how we collect, use, and protect your
                    personal information, please refer to our
                    <a href="/privacy-policy">Privacy Policy.</a>
                    By using our services, you agree to both this Terms of
                    Service and our Privacy Policy.
                </p>

                <h2 class="text-center ma-6">
                    DISCLAIMER - COMPENSATORY AFFILIATION
                </h2>
                <p>
                    This site may be affiliated with companies whose products
                    and services are being promoted on this site, as a Marketing
                    Affiliate and receive compensation for sales of such
                    products and services.
                </p>

                <h2 class="text-center ma-6">
                    EARNINGS DISCLAIMER AND FORWARD LOOKING STATEMENTS
                </h2>
                <p>
                    Every effort has been made to accurately represent the
                    products and services found on this site and their
                    potential. However, there is no guarantee that a customer
                    will earn any money using the products and services found on
                    this site, or the techniques or ideas in these materials.
                    Examples in these materials are not to be interpreted as a
                    promise or guarantee of earnings in any way.
                </p>
                <p>
                    Materials in the product and on our website may contain
                    information that includes or is based upon Forward-Looking
                    Statements within the meaning of the Securities Litigation
                    Reform Act of 1995. Forward-Looking Statements present our
                    expectations or forecasts of future events. You can identify
                    these statements by the fact that they do not relate
                    strictly to historical or current facts. Such
                    Forward-Looking Statements use words such as "anticipate,"
                    "estimate," "expect," "project," "intend," "plan,"
                    "believe," and other such words and terms of similar meaning
                    in connection with a description of potential earnings or
                    financial performance. Your results may vary from ours and
                    those of anyone else that is, or will be using these
                    products, techniques, and/or services.
                </p>
                <p>
                    Any and all Forward-Looking Statements here or on any of our
                    sales material are intended to express our opinion of
                    earnings potential not actual earnings expected. Many
                    factors will be important in determining your actual results
                    and no guarantees are made, expressed or implied that you
                    will achieve results similar to ours or anybody else's.
                </p>

                <h2 class="text-center ma-6">
                    REFUND, CANCELLATION AND CARRYFORWARD POLICY
                </h2>
                <p>
                    Each cancellation request must be officially communicated to
                    {{ businessName }} via
                    <a :href="'mailto:' + businessEmail">
                        {{ businessEmail }}
                    </a>
                    .
                </p>

                <h3 class="text-center ma-6">
                    Specific guidelines regarding cancellations:
                </h3>
                <p>
                    All {{ businessName }}
                    product cancellation requests must be communicated at least
                    3 business days prior to their official renewal date.
                </p>
                <p>
                    Cancellations made outside of the specified time-frame will
                    be accepted but do not qualify for a refund.
                </p>
                <p>
                    Steps required in order to successfully cancel a
                    subscription:
                </p>
                <ol class="pb-4">
                    <li>
                        Customer must request cancellation by emailing
                        <a :href="'mailto:' + businessEmail">
                            {{ businessEmail }}
                        </a>
                        .
                    </li>
                    <li>
                        Support will confirm the receipt of the request to the
                        customer.
                    </li>
                    <li>
                        {{ businessName }}
                        will address the request.
                    </li>
                </ol>
                <p>
                    <strong>Important:</strong>
                    Responding to all communications from
                    {{ businessName }}
                    , especially to confirm cancellation requests, is highly
                    recommended in order to prevent unwanted charges.
                </p>
                <p>
                    {{ businessName }}
                    might take a certain period of time to address any
                    misunderstandings or issues that motivated the customer's
                    cancellation request.
                </p>
                <p>
                    Nevertheless, if the customer discontinues further use of
                    the subscription, and no resolution is reached for their
                    concerns,
                    {{ businessName }}
                    will honor the initial cancellation request date.
                </p>
                <p>
                    Should a cancellation request meeting the above conditions
                    not be processed by
                    {{ businessName }}
                    before the subscription renewal date,
                    {{ businessName }}
                    will refund the customer for any fees incurred.
                </p>

                <h3 class="text-center ma-6">
                    Specific guidelines regarding refunds:
                </h3>
                <ol class="pb-4">
                    <li>
                        {{ businessName }} customers are not entitled to refunds
                        as the general rule. However, occasional refunds may be
                        awarded as a gesture of good-will.
                        <br />
                        Each request will be assessed by the
                        {{ businessName }} team, and the decision to refund
                        depend exclusively on {{ businessName }}.
                    </li>
                    <li>
                        Some special promotions are covered by a 60-day money
                        back guarantee, where the customer is entitled to a full
                        refund on the initial purchase. For this guarantee to be
                        applicable, it has to be displayed within the Sales page
                        of a specific promotion.
                        <br />
                        Subsequent purchases are not covered by this 60-day
                        money back guarantee.
                    </li>
                    <li>
                        {{ businessName }} has a strict policy regarding any
                        refunds it decides to issue of over $8,000.00; as these
                        will be reimbursed exclusively via Bank transfer.
                        <br />
                        In the case of a refund exceeding the $8,000.00
                        threshold, the customer agrees to provide their banking
                        details when requested, so the refund can be issued.
                    </li>
                    <li>
                        No partial refunds will be issued regarding subscription
                        charges; However, partial refunds may be granted for
                        non-subscription products and services.
                    </li>
                    <li>
                        The refund process as a whole can take up to 2-3 weeks,
                        and it generally (with exceptions) presents the
                        following structure:
                        <br />
                        <strong>
                            <em>
                                Customer requests refund to
                                {{ businessName }}'s support team > Support
                                assesses the refund request >
                                {{ businessName }} decides to award a refund or
                                not, and communicates the decision to the
                                customer > If awarded, {{ businessName }}'s
                                Finance department issues the refund > Payment
                                processor (or bank) receives the money > Payment
                                processor (or bank) credits the money to
                                customer's account.
                            </em>
                        </strong>
                    </li>
                </ol>
                <p>
                    Customers must refrain from raising disputes or Charge-backs
                    while an internal refund process is ongoing. Especially if
                    {{ businessName }}'s support team has informed the customer
                    that the refund request is being processed.
                </p>
                <p>
                    If the customer were to raise a dispute or a charge-back
                    while the refund assessment process is ongoing, it will
                    result in a failed attempt to refund, as it will be
                    automatically blocked by the billing platform.
                </p>
                <p>
                    Requesting a refund and raising a dispute or charge-back at
                    the same time could be considered as a fraud attempt, as
                    there is an intent to be refunded twice. Customers may have
                    their right of service revoked at {{ businessName }}'s
                    discretion, including access to their
                    {{ businessName }} accounts. Additionally, payment
                    processors might flag the customer's account as fraudulent
                    and restrict access to it.
                </p>
                <p>
                    Customers that file credit card or PayPal disputes
                    untruthfully or incorrectly may have their right of service
                    revoked at our discretion.
                </p>
                <p>
                    Asigo System customers are solely responsible for disputes,
                    charge-backs, and refunds resulted from transactions with
                    their end clients. Furthermore,
                    {{ businessName }} reserves the right to withdraw revenues
                    should customers fail to do so.
                </p>
                <p>
                    Customers that do not pay their
                    {{ businessName }} product fees (monthly subscriptions,
                    annual subscriptions or split-pay packages) may have their
                    right of service revoked at our discretion, including access
                    to their {{ businessName }} accounts. Non-paid fees are
                    defined as any delinquent payments for
                    {{ businessName }} products that are not resolved (paid in
                    full) by the customer within 27 days of their original
                    payment due date. For split-pay packages, Customers agree to
                    pay the total fee of the package over consecutive monthly
                    installments, as defined by {{ businessName }}, and to a
                    fractioned delivery of full package credits, in equal
                    amounts, per paid installment.
                </p>
                <p>
                    Prepayment for distribution services (credits) have a
                    validity of 24 months after date of purchase. Additionally,
                    the company shall not be obligated to provide a certain
                    distribution service if any of the following three factors
                    shall be in effect:
                </p>
                <ul>
                    <li>
                        This site is no longer in the business of distributing
                        news articles;
                    </li>
                    <li>
                        Suitable distribution channels are not available; or
                    </li>
                    <li>
                        The cost of distribution has risen to such an extent
                        that this site cannot economically provide such service
                        given the amount prepaid.
                    </li>
                </ul>

                <h2 class="text-center ma-6">
                    {{ businessName }} Credits Policy
                </h2>

                <p>
                    {{ businessName }} offers a variety of credits that provide
                    access to specific content services and distribution
                    channels. These credits function as a flexible way for
                    customers to utilize our platform, with the exact services
                    and distribution methods outlined during the sales process.
                </p>

                <h3 class="text-center ma-6">
                    Specific guidelines regarding credits:
                </h3>
                <ol>
                    <li>Credits are non-transferable between accounts.</li>
                    <li>
                        Credits are non-restorable due to errors made by
                        customers in the publication process.
                    </li>
                    <li>
                        Credits have a validity of 24 months from the time of
                        purchase, after which these will expire.
                    </li>
                    <li>Expired credits are non-refundable.</li>
                </ol>

                <h3 class="text-center ma-6">Additional Information:</h3>
                <p>
                    Customers are responsible for ensuring they use their
                    credits within the validity period.
                </p>
                <p>
                    Credits may be redeemed only for the specified services at
                    the time of purchase. In cases where a service or
                    distribution channel becomes unavailable, the company
                    reserves the right to offer an alternative of equal value.
                </p>

                <h2 class="text-center ma-6">DMCA DISCLAIMER</h2>
                <p>
                    This site respects the intellectual property of others. If
                    you believe that your work has been copied in a way that
                    constitutes copyright infringement, please follow our Notice
                    and Procedures for Making Claims of Copyright Infringement
                    below.
                </p>

                <h3 class="text-center ma-6">
                    Notice and Procedure for Making Claims of Copyright
                    Infringement Pursuant to the Digital Millennium Copyright
                    Act
                </h3>
                <p>
                    If you believe that you hold a claim of copyright
                    infringement against this site, submit notice of your claim
                    via email to
                    <a :href="'mailto:' + businessEmail">
                        {{ businessEmail }}
                    </a>
                </p>
                <p>
                    Email Address to Which Notification Should Be Sent:
                    <a :href="'mailto:' + businessEmail">
                        {{ businessEmail }}
                    </a>
                </p>
                <p>
                    To be effective, the notification of your claim of copyright
                    infringement should be written and should include the
                    following:
                </p>
                <ul>
                    <li>
                        <p>
                            A statement that you are the owner of the exclusive
                            right you claim has been infringed, or a statement
                            that you are authorized to act on behalf of the
                            owner of an exclusive right that has allegedly been
                            infringed.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <p>
                            A statement, under penalty of perjury, that the
                            information in the notification is accurate. Your
                            signature. (The signature may be electronic.)
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <p>
                            The identification of the copyrighted work you claim
                            has been infringed. (If you claim that multiple
                            copyrighted works have been infringed, you can
                            submit one notification with a list of the allegedly
                            infringed works.)
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <p>
                            Identification of the material that you claim to be
                            infringing and information reasonably sufficient to
                            permit this site to locate the material.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <p>
                            Information reasonably sufficient to permit this
                            site to contact you, including your address,
                            telephone number, fax number and electronic mail
                            address. You can provide contact information for the
                            owner of the exclusive right that you claim has been
                            infringed if you are not the owner, but rather
                            authorized to act on behalf of the owner.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <p>
                            A statement you have a good-faith belief that use of
                            the material in the manner complained of is not
                            authorized by the copyright owner, its agent, or the
                            law.
                        </p>
                    </li>
                </ul>
                <p>
                    This site will respond to any DMCA complaints in a timely
                    manner. Should you be the copyright holder of a specific
                    piece of content featured on this site without your
                    permission, this site will remove it in a timely manner once
                    notified.
                </p>

                <h3 id="amp-guidelines" class="text-center ma-8">
                    News Article Content Rules and Terms
                </h3>
                <p>
                    The documents submitted to
                    {{ businessName }} shall not contain any content that is
                    obscene, libelous, slanderous or otherwise defamatory, false
                    or misleading or which violates any copyright, right of
                    privacy or publicity or other right of any person and agree
                    to comply with all applicable laws, rules and regulations,
                    including to all those applicable laws, rules and
                    regulations relating to "SPAM".
                </p>
                <p>
                    Under all circumstances,
                    {{ businessName }} reserves the right at all times and
                    without previous notification to approve, disapprove or
                    remove parts or all the published/submitted documents as
                    long as it is inappropriate to be published. Any decision
                    made by the {{ businessName }} team shall be FINAL and it
                    cannot be questioned.
                </p>
                <p>
                    Under all circumstances, content distributed by
                    {{ businessName }} can be removed without prior
                    notification. Distribution partners receiving
                    {{ businessName }}'s content reserve the right to remove any
                    or all content sourced from {{ businessName }} at their
                    discretion.
                </p>
                <p>
                    No promotional news articles will be accepted as our service
                    should not be used for your advertisements. Company's
                    website URL that contains pop-up and pop-unders ads also
                    will not be accepted. Such news articles will be removed
                    from our database without any notifications. Members are not
                    allowed to misuse our service in order to promote their
                    services violating news article submission conditions.
                    Violation will result in permanent removal of account and
                    their releases.
                </p>

                <h3 class="text-center ma-8">Headline and Summary</h3>
                <ul>
                    <li>
                        <p>
                            Headline must not be too long (under 80 characters),
                            and have active verb and subject.
                        </p>
                    </li>
                    <li>
                        <p>
                            Headline should accurately present news article's
                            content.
                        </p>
                    </li>
                    <li>
                        <p>
                            Headline must be newsworthy (important and recent
                            enough to be announced).
                        </p>
                    </li>
                    <li>
                        <p>
                            Summary should clearly describe news article's
                            content.
                        </p>
                    </li>
                    <li>
                        <p>Your summary should be one to two sentences long.</p>
                    </li>
                    <li>
                        <p>
                            Please do not use first paragraph as summary
                            paragraph.
                        </p>
                    </li>
                </ul>

                <h3 class="text-center ma-8">News Article Format</h3>
                <ul>
                    <li>
                        <p>
                            News Article word count should be between 300 to 800
                            words.
                        </p>
                    </li>
                    <li>
                        <p>
                            News Article should be objective - don't use casual
                            first person language (I, we, you, etc.)
                        </p>
                    </li>
                    <li>
                        <p>
                            Attach only one image per news article not more than
                            200KB in file size (jpg, png or gif only).
                        </p>
                    </li>
                    <li>
                        <p>
                            Video should be embedded correctly into news
                            article.
                        </p>
                    </li>
                    <li>
                        <p>
                            Content should have a legitimate news angle
                            (announcing something new and/or timely).
                        </p>
                    </li>
                    <li>
                        <p>
                            Don't write your news article like an advertisement
                            or a sales letter.
                        </p>
                    </li>
                    <li>
                        <p>
                            Content should keep an impartial tone throughout the
                            news article.
                        </p>
                    </li>
                    <li>
                        <p>Please don't ask question(s) in a news article.</p>
                    </li>
                    <li>
                        <p>
                            Use uppercase and lowercase lettering appropriately.
                        </p>
                    </li>
                    <li>
                        <p>
                            Avoid inserting JavaScript or other markup languages
                            in your news article, such as
                            &lt;strong&gt;…&lt;/strong&gt; or
                            &lt;em&gt;…&lt;/em&gt;. ONLY links eg. &lt;a
                            href="URL"&gt;…&lt;/a&gt; are acceptable.
                        </p>
                    </li>
                    <li>
                        <p>Please ensure links are formatted correctly.</p>
                    </li>
                    <li>
                        <p>
                            Make sure each paragraph is spaced out with a line.
                        </p>
                    </li>
                    <li>
                        <p>
                            Please ensure news article is properly formatted,
                            e.g., do not double space unnecessarily. Use
                            appropriate punctuation and symbols.
                        </p>
                    </li>
                    <li>
                        <p>
                            Only include a quote that someone actually might
                            have said.
                        </p>
                    </li>
                    <li>
                        <p>
                            News Article links should be limited to 1 per 200
                            words.
                        </p>
                    </li>
                    <li>
                        <p>
                            News Articles should not contain references or links
                            to sexually explicit material, illegal material or
                            profane language.
                        </p>
                    </li>
                    <li>
                        <p>
                            Content should not have intent to harm or exact
                            personal revenge against a person or group.
                        </p>
                    </li>
                    <li>
                        <p>
                            Spam related words that will be filtered out by spam
                            filters should be refrained.
                        </p>
                    </li>
                    <li>
                        <p>
                            Include available contact information where
                            possible.
                        </p>
                    </li>
                    <li>
                        <p>
                            Do not place email addresses within the body of the
                            news article.
                        </p>
                    </li>
                    <li>
                        <p>
                            Include the city from where the release originates,
                            usually the location of the company's headquarters.
                        </p>
                    </li>
                    <li>
                        <p>
                            Content should contain no or few grammatical and
                            spelling errors.
                        </p>
                    </li>
                    <li>
                        <p>
                            Do not use special characters and symbols in
                            headline such as ™ or ©.
                        </p>
                    </li>
                </ul>

                <h3 class="text-center ma-6">Content</h3>
                <ul>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases that
                            contain only opinion.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases that are
                            duplicated content.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases that
                            promote mortgage, pay day, cash, short-term or
                            long-term loan services.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases that
                            promote escort, sexual enhancement products or
                            supplements.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases
                            containing radical or political opinion/view.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases
                            containing radical religious opinion/view.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases with
                            intent to harm or defame.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases
                            containing unauthorized stock ticker symbols.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases that
                            promote online gambling.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept topics regarding
                            e-cigs, green coffee, raspberry ketones, HCG,
                            Kratom, Garcinia Cambogia and more.
                        </p>
                    </li>
                    <li>
                        <p>
                            Content should keep an impartial tone throughout the
                            news article.
                        </p>
                    </li>
                    <li>
                        <p>
                            Content should contain no or few grammatical and
                            spelling errors.
                        </p>
                    </li>
                    <li>
                        <p>
                            Don't write your news article like an advertisement
                            or a sales letter.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept topics regarding
                            illegal device unlocking, rooting or jail-breaking
                            of devices.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept releases that
                            announces discounts, bonus, price cuts, sales,
                            coupon codes or anything related to promotion of
                            products/services, advertorials or news about
                            promotions.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept content that
                            reads like an article without news value.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept content about
                            affiliate product reviews, product reviews or
                            trials.
                        </p>
                    </li>
                    <li>
                        <p>
                            {{ businessName }} does not accept content about new
                            website launches, new blog posts or content that
                            generally does not carry a news value.
                        </p>
                    </li>
                </ul>
                <p>
                    Disclaimer: Under any circumstances, the
                    {{ businessName }} team reserve the right at all times and
                    without previous notification to approve, disapprove or
                    remove parts or all the published/submitted documents as
                    long as it is inappropriate to be published. Any decision
                    made by the {{ businessName }} team shall be FINAL and
                    cannot be questioned.
                </p>
                <p>
                    These terms will change from time to time without prior
                    notice and you are advised to visit this page for updates.
                </p>

                <h3 class="text-center ma-6">General Disclaimer</h3>
                <p>
                    Our products, services, and training evolve over time to
                    adapt to changing market conditions. As such, we cannot
                    guarantee marketing claims or availability of specific
                    content brands, but we do guarantee best efforts in
                    replacing any with equivalent alternatives.
                </p>

                <div v-if="isAmpifire">
                    <h3 class="text-center mt-6 mb-4">Contact Us</h3>
                    <ul class="pb-4">
                        <li>AmpiFire</li>
                        <li>
                            <a :href="'mailto:' + businessEmail">
                                {{ businessEmail }}
                            </a>
                        </li>
                        <li>
                            UK Office: 15 Harwood Road, Fulham, London, SW6 4QP.
                        </li>
                        <li>
                            HK Office: 14/F China Hong Kong Tower, 8 Hennessy
                            Road, Wanchai.
                        </li>
                    </ul>
                </div>
                <div v-if="shouldAgree" class="text-center my-6">
                    <a-form :submit="save">
                        <a-toggle
                            v-model="agreed_to_tos"
                            checkbox
                            :disabled="isSaving"
                            label="I agree to the Terms of Use Agreement"
                        />
                        <div class="text-center">
                            <v-btn
                                type="submit"
                                color="primary"
                                large
                                :loading="isSaving"
                                :block="$vuetify.breakpoint.smAndDown"
                                :disabled="!agreed_to_tos"
                            >
                                Agree
                            </v-btn>
                        </div>
                    </a-form>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

import { isClientCabin, getScrollParent } from '@/utils/helpers';

import { RootState } from '@/store/State';

import { AForm } from '@/components/AForm';
import { AToggle } from '@/components/AForm/Inputs/AToggle';
import { Watch } from '@/utils/decorators';

@Component({
    components: {
        AForm,
        AToggle
    },
    computed: {
        ...mapState({
            userId: (state): number | void => (state as RootState).user?.id
        }),
        ...mapState('user', ['businessName', 'businessEmail'])
    }
})
export default class TermsOfService extends Vue {
    userId!: number;
    businessName!: string | null;
    businessEmail!: string | null;

    isAmpifire = !isClientCabin();

    shouldAgree = false;

    agreed_to_tos = false;

    isSaving = false;

    @Watch('userId')
    onUserId(id: number) {
        if (id) {
            this.handleAgreement();
        }
    }

    mounted() {
        if (window.location.hash) {
            setTimeout(() => {
                this.scrollTo(window.location.hash);
            }, 1000);
        }
    }

    scrollTo(href: string) {
        const element = document.getElementById(href.replace('#', ''));

        if (element) {
            const container = getScrollParent(element) as HTMLElement;

            if (container) {
                this.$vuetify.goTo(element, {
                    container,
                    offset: 20
                });
            }
        }
    }

    handleAgreement() {
        if (this.userId) {
            this.getUserData().then(({ data }) => {
                this.agreed_to_tos = Boolean(data.user?.agreed_to_tos || false);

                if (!this.agreed_to_tos) {
                    this.shouldAgree = true;
                }
            });
        }
    }

    async getUserData() {
        return this.$http
            .get('/users/panel')
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
                return {};
            });
    }

    getDataToSave() {
        return (({ agreed_to_tos }) => ({
            agreed_to_tos: Number(agreed_to_tos)
        }))(this);
    }

    save() {
        this.setSaving();

        return this.$http
            .post('/users/panel', this.getDataToSave())
            .then(({ data }) => {
                if (!data) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to save your agreement to our Terms of Service. Please try again later.'
                    );
                }

                this.shouldAgree = false;
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            })
            .finally(this.setSaving.bind(this, false));
    }

    setSaving(isSaving = true) {
        this.isSaving = isSaving;
    }
}
</script>

<style lang="scss" scoped>
.terms::v-deep {
    #privacy,
    #cookies {
        scroll-margin-top: 6rem;
    }
}
</style>
